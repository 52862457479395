import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// import Helper from "../../../../Configs/Helper";
import PlacesAutocomplete, {
  // geocodeByAddress,
  // getLatLng,
} from 'react-places-autocomplete';
class FormStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    //console.log(this.props);
    return (
      <div className="box-body">
      <ul className="deailsList">
      <li>
      <div>
        <div className="label">Location </div>
        <div className="details">
        <PlacesAutocomplete
        value={this.props.state.s2_location}
        onChange={this.props.handleLocationChange}
        onSelect={this.props.handleLocationSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: '',
                className: 'form-control location-search-input',
                autoComplete: "nope"
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.address}</span>
        </div>
      </div>
    </li>
        <li>
          <div>
            <div className="label">Address <span className="required_field">*</span></div>
            <div className="details">
              <textarea className="form-control"  name="s2_address" value={this.props.state.s2_address} onChange={this.props.onChnageHandler}/>
              <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.address}</span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="label">Country <span className="required_field">*</span></div>
            <div className="details">
              <input type="text" className="form-control" name="s2_country" value={this.props.state.s2_country} onChange={this.props.onChnageHandler}/>
              <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.country}</span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="label">State <span className="required_field">*</span></div>
            <div className="details">
              <input type="text" className="form-control" name="s2_state" value={this.props.state.s2_state} onChange={this.props.onChnageHandler}/>
              <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.state}</span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="label">City <span className="required_field">*</span></div>
            <div className="details">
              <input type="text" className="form-control" name="s2_city" value={this.props.state.s2_city} onChange={this.props.onChnageHandler}/>
              <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.city}</span>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="label">Postal Code <span className="required_field">*</span></div>
            <div className="details">
              <input className="form-control" name="s2_zipcode" value={this.props.state.s2_zipcode} onChange={this.props.onChnageHandler}/>
              <span className="errorMsg errorStepForm">{this.props.state.companyAddressFormErrors.zipcode}</span>
            </div>
          </div>
        </li>
        {/* <li>
          <div>
            <div className="label">Fax no</div>
            <div className="details">
              <input type="text" className="form-control" name="s2_fax_num" value={this.props.state.s2_fax_num} onChange={this.props.onChnageHandler}/>
            </div>
          </div>
        </li> */}
      </ul>
    </div>
    
    );
  }
}

export default (FormStep2);
