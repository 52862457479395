//Application Header
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { userLogoutAction,
          getNotifications,
          updateReadStatus,
          getTypeaheadSearchResult,
          getCompanyDetailsByIdAction,
          setSelectedOtherCompanyInfoAction,
          getReviewBycompanyidAction } from "../../../store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import '../../../style/css/style.css';
import Constants from '../../../utils/Constants';
import{Button, Form, FormControl, Nav, NavDropdown, Navbar} from 'react-bootstrap';
import constants from "../../../utils/Constants";
import Notifications from '../../Components/GlobalPages/Notification';
import moment from 'moment';
import Helper from "../../../Configs/Helper";


const COMPANY_DEFAULT_LOGO = Constants.defaultImageUrls.companyLogo;
const USER_DEFAULT_AVATAR = Constants.defaultImageUrls.user;
const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text:'', 
      display:'none',
      ACCOUNT_ID:this.props.userData.data.account_id,
      notificationDetails: [],
      unReadNotification: 0,
  };
  this.getAllNotifcation=this.getAllNotifcation.bind(this);
  }
  openModal(type, e) {
    this.props.openModal(type);
  }
  logout = async ()=>{
    await this.props.userLogoutAction();
   // this.props.history.push('/');
    window.location.assign('/');
  }
  clickPrevent = (e) =>{
    e.preventDefault();
  }

   showDrop = async(e)=>{
    this.setState({  text:e.target.value})
      if(this.state.text.length > 2 ){

        let requestParams = {
          keywords:this.state.text,
          fetch_size:5
              }
    
       await this.props.getTypeaheadSearchResult(requestParams);
          if (this.props.typeaheadSearchresult.status === 200) {
            console.log("RESPONSE OF typeaheadSearchresult");
            console.log(this.props.typeaheadSearchresult);
            console.log("");
          }
  
        this.setState({display:'block'})
      }else{
        this.setState({display:'none'})    
      } 
    }
    
  getAllNotifcation =async(mve) => {

    let requestParams = {
      account_id: this.state.ACCOUNT_ID,
      // type: "push",
      sent_status: 1,
      is_show_panel: 1,
      is_total_unread: 1,
      // read_status: 0,
      page_size: 5
    }

   await this.props.getNotifications(requestParams);

      console.log("RESPONSE OF GET NOTIFICATIONS");
      console.log(this.props.getNotificationsres);
      console.log("");
      if(mve===1)
      {      this.props.history.push('/Notification');
        } 
      if (this.props.getNotificationsres.status === 200) {
        this.setState({
          notificationDetails: this.props.getNotificationsres.data.notification_logs,
          unReadNotification: this.props.getNotificationsres.data.totalUnreadNotification
        });

        this.calculateNotificationTime(this.props.getNotificationsres.data.notification_logs);
      }
  }

  componentDidMount() {
    this.getAllNotifcation(2);
  }

  updateReadStatus = async() => {

    if (this.state.unReadNotification !== 0) {

      let requestParams = {
        account_id:'5e1eb1b52e86b584a4985c26'
        //  this.state.ACCOUNT_ID
      }
      await this.props.updateReadStatus(requestParams);

        console.log("RESPONSE OF UPDATE READ STATUS OF NOTIFICATION");
        console.log(this.props.updateReadStatusres);
        console.log("");

        if (this.props.updateReadStatusres.status === 200) {
          this.setState({
            unReadNotification: 0
          })
        }
    }
  }

  diffYMDHMS = (date1,date2) => {
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    var seconds = date1.diff(date2, 'seconds');

    console.log(years + ' years ' + months + ' months ' + days + ' days ' + hours + 'hours ' + minutes + ' minutes ' + seconds + ' seconds');

    return { years, months, days, hours, minutes, seconds };
  }

  formatNotificationTime = (diffTimeObject, indexOfNotification) => {

    let notification_data = this.state.notificationDetails;

    if (diffTimeObject.years == 0 && diffTimeObject.months != 0) {
      notification_data[indexOfNotification].notification_time = diffTimeObject.months + " month";

    } else if (diffTimeObject.months == 0 && diffTimeObject.days != 0) {
      notification_data[indexOfNotification].notification_time = diffTimeObject.days == 1 ? diffTimeObject.days + " day" : diffTimeObject.days + " days";
      
    } else if (diffTimeObject.days == 0 && diffTimeObject.hours != 0) {
      notification_data[indexOfNotification].notification_time = diffTimeObject.hours + " hours";

    } else if (diffTimeObject.hours == 0 && diffTimeObject.minutes != 0) {
      notification_data[indexOfNotification].notification_time = diffTimeObject.minutes + " minutes";

    } else if (diffTimeObject.minutes == 0) {
      notification_data[indexOfNotification].notification_time = "just now";
    }

    this.setState({
      notificationDetails: notification_data
    });
  };

  calculateNotificationTime = (notification_data) => {
    var current_datetime = moment();

    for (var i = 0; i < notification_data.length; i++) {

      var creation_date_time = moment(notification_data[i].creation_ts * 1000);
      var diffOfDates = this.diffYMDHMS(current_datetime, creation_date_time);

      console.log("");
      console.log("DIFFRENET BETWEEN TWO DATES");
      console.log(diffOfDates);
      console.log("");

      this.formatNotificationTime(diffOfDates, i);
    }
  }
  goToSearchPage=btn=>async(e)=>{

  this.setState({  text:this.state.text})
  if(this.state.text.length > 2 ){

    let requestParams = {
      keywords:this.state.text,
          }
   await this.props.getTypeaheadSearchResult(requestParams);
      if (this.props.typeaheadSearchresult.status === 200) {
        var path='Search';
        this.props.history.push(path,btn)
      }

    this.setState({display:'block'})
  }else{
    this.setState({display:'none'})    
  } 
  }
  goToCompanyTimeline = company_id => async (e) =>{
    this.setState({display:'none'})
    if(this.props.location.pathname==="/company-timeline"){
      this.props.goToTimeLine(company_id);
    }else{
    // this.loader.show(true);

    //set default company Id
    Helper.setDefaultCompany(company_id);

    // get selected company details
    const paramsData = {
        account_id:this.props.userData.data.account_id,
        requester_id: Helper.getDefaultCompanyId(),
        company_id:company_id,
    }
    await this.props.getCompanyDetailsByIdAction(paramsData);
    if(this.props.companyDetails.status===200){
        await this.props.setSelectedOtherCompanyInfoAction(this.props.companyDetails);
      //get reviews   
          await this.props.getReviewBycompanyidAction({company_id:company_id})
             Helper.setReviewCounter(this.props.reviewerData);
            this.props.history.push('/company-timeline')
    } else {
        // this.loader.showAlert(true,'error',this.props.companyDetails.message)
    }
    // this.loader.show(false);
}
  }
allNotifications=()=>{
  this.getAllNotifcation(1);

}
  render() {
    console.log('this.props.text');
    console.log(this.props.text);
    console.log(this.state.text);
      const ownCompanyId = localStorage.getItem('COMPANY_ID');
    const logo = 'images/logo/logo.png';
    const { userData, headerType } = this.props;
    if(headerType==="beforeLogin"){
      return (
        <header id="header" className="offscroll">
          <div className=" container h-100">
            <div className="row d-flex align-items-center">
              <div className="logo">
                <Link to="/home"><img alt="" src={logo} /> </Link>
              </div>
              <div className="topMenu">
                <ul>
                { userData===null ? <>
                  <li> 
                    
                    <Link to="#" id="signupbtn" className="btn btn-default" data-toggle="modal" data-target="#signUpModal" onClick={this.openModal.bind(this, "signUpModal")}>
                      <i className="flaticon-user" />
                      <span>Sign Up</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="btn btn-default" id="loginbtn" data-toggle="modal" data-target="#loginModal" onClick={this.openModal.bind(this, "loginModal")}>
                      <i className="flaticon-login" />
                      <span>Login</span>
                    </Link>
                  </li></>
                  : 
                  <li className="dropdown">
                  <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img alt="" src="images/user.svg" />
                    {userData.data.first_name}
                  </button>
                  <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                    <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
                  </div>
                </li>
              }
                </ul>
              </div>
            </div>
          </div>
        </header>
      );
    } else if(headerType==="companyOptions" || headerType==="createCompany" || headerType==="termsConditions") {
      return (<header id="header" className=" offscroll">
          <div className="container h-100">
            <div className="row d-flex align-items-center">
              <div className="logo">
                <a href="/"><img alt="" src={logo} /> </a>
              </div>
              <div className="topMenu">
                
              </div>
            </div>
          </div>
        </header>)
    } else if(headerType==="afterLogin" || headerType==="userDashboard" || headerType==="companyDashboard" || headerType==="newsFeed"){
      return (
      <header id="header" className="">
      <div className="container">
        <div className="">
        <Navbar expand="lg">
        <div className="logo col-sm-12 col-md-6 padding-0">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="">
          <a href="#" className="navbar-brand" onClick={this.clickPrevent}>
            <img alt="" src="images/logo/logo.png" />
          </a>
        </Navbar.Brand>
        </div>
        
        <div className="topMenu col-sm-12 col-md-6 padding-0">
        <div className="upperContainer">
          <div className="searchInput">
            <input className="form-control" value={this.state.text} onChange={this.showDrop} />
            <Link to="/Search"   className="icon">
              <i className="flaticon-search" />
            </Link>
          </div>
          <div className="searchBox" style={{display:this.state.display}}>
            <div className="searchBoxContent border bg-white">
              <ul className="searchboxlist">
                 {this.props.typeaheadSearchresult!==null && this.props.typeaheadSearchresult!==undefined &&
                this.props.typeaheadSearchresult.data!==null && this.props.typeaheadSearchresult.data!==undefined ? 
                    this.props.typeaheadSearchresult.data.search_result.length === 0 ? 
                  <li>
                     <span>No details.</span>
                 </li>
                 :
                 <PopulateSearch typeaheadSearchresult={this.props.typeaheadSearchresult} goToCompanyTimeline={this.goToCompanyTimeline}/>
                 : <li><span>No details.</span></li>
              } 
              </ul>
              {/* <Link to="/Search" className="searchbottom"> */}
              <span className="searchbottom">
                <button className="btn" onClick={this.goToSearchPage(2)}>See all result</button>
                </span>
              {/* </Link> */}
            </div>
          </div>
        </div>
        <div className="lowerContainer ">   
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="">
          <Link to="/news-feed" className="btn btn-default linkBtn" title="Home">
            <i className="fa fa-home" aria-hidden="true"></i>
            <span>Home</span>
          </Link>

          {(ownCompanyId!=='' && ownCompanyId!==null && ownCompanyId!==undefined) &&
           <Link to="/company-timeline/connections" className="btn btn-default linkBtn connection" title="Network">
              <i className="flaticon-network" />
              <span>Networks</span>
            </Link> }

          <Link to="/messages" className="btn btn-default linkBtn" title="Message">
            <i className="fa fa-envelope" aria-hidden="true"></i>
            <span>Message</span>
          </Link>
          <Nav.Link className="dropdown btn btn-default linkBtn" title="Notifications">
                  <a href="#" onClick={this.clickPrevent} className=""  role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {/* <i className="flaticon-alarm" /> */}
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    <span className="badge badge-danger">2</span>
                    <span>Notifications</span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right customDropdown" aria-labelledby="dropdownMenuLink">
                    <div className="c-header bg-danger text-white">
                      <div>
                        <span>Notifications</span>
                      </div>
                      <div />
                    </div>
                    <div className="c-body">
                      <ul>
                      <Notifications notificationDetails={this.props.getNotificationsres}/>
                      </ul>
                      {/* <Link to="/Notification" className="searchbottom"> */}
                      <span className="searchbottom">
                        <button className="btn" onClick={this.allNotifications}>See all</button>
                        </span>
                    {/* </Link> */}
                    </div>
                  </div>
                </Nav.Link>

          <Nav.Link href="#" className="btn btn-default linkBtn" onClick={this.clickPrevent} title="Rating">
            <i className="fa fa-handshake-o" aria-hidden="true"></i>
            <span>On Board</span>
          </Nav.Link>
          <Link to="company-timeline/review" className="btn btn-default linkBtn" title="Rating">
            <i className="fa fa-star" aria-hidden="true"></i>
            <span>Rating</span>
          </Link>
          <Nav.Link href="#" className="btn btn-default linkBtn" title="CIX" onClick={this.clickPrevent}>
            <i className="fa fa-refresh" aria-hidden="true"></i>
            <span>CIX</span>
          </Nav.Link>

          <Nav.Link className="dropdown header_user linkBtn   linkBtn" style={{paddingTop:'2px'}}>
            <button type="button" className="btn userBtn" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img alt="userAvatar" src={(userData.data.avatar_image!==null && userData.data.avatar_image!==undefined) ? BUCKET_PATH + "/" + userData.data.avatar_image.image_path :USER_DEFAULT_AVATAR} />
            </button>
            <div className="dropdown-menu " aria-labelledby="dropdownMenuLink">
              <Link to="/user"><span className="dropdown-item act-span"> Profile </span></Link>
              <Link to="/news-feed"><span className="dropdown-item act-span"> News Feed </span></Link>
              <span className="dropdown-item act-span" id="logout-btn" onClick={this.logout}> Logout </span>
            </div>
          </Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
        </div>

        

        </div>
      </Navbar>

      </div>
      </div>
    </header>
    
    )
    }
    return (<div>&nbsp;</div> );
  }
}

function PopulateSearch(props) {

  var searchResultres = props.typeaheadSearchresult
  let list=searchResultres.data.search_result.map((result)=>{
    var data=searchResultres.data;
    var image='';
    if(result.poster_id!==null){
      if(data.poster_detail[result.poster_id].image!==null){
          image=data.poster_detail[result.poster_id].image
        }else{ image=COMPANY_DEFAULT_LOGO}
    }
    else if(result.company_profile_url!==null){
       image=result.company_profile_url ;
    }else{image=COMPANY_DEFAULT_LOGO}

    return (
              <li>
                  <a href="#">
                  <div className="img">
                    <img src={image!==COMPANY_DEFAULT_LOGO ? BUCKET_PATH + "/" + image:image}/>
                  </div>
                  <div className="details">
                  {result.company_name!==null ?
                    <p><a href="#" onClick={props.goToCompanyTimeline(result.id)}>{result.company_name}</a></p>
                                                                  :
                    <p><a href="#" onClick={props.goToCompanyTimeline(result.poster_id)}>{searchResultres.data.poster_detail[result.poster_id].name}</a></p>
                  }
                    {result.company_type!==null && <p><b>Type:</b>{result.company_type} &nbsp; &nbsp; | &nbsp; &nbsp; <b>Industry:</b> {result.industry_type}</p>}
                    <p>{result.post_text!==null && <><b>Post text:</b>&nbsp;{result.post_text}</>}</p>
                  </div>
                  </a>
                </li>
            );
  });
  return list
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  getNotificationsres: state.apiData.getNotificationsres,
  companyDetails: state.appData.companyDetails,
  updateReadStatusres: state.apiData.updateReadStatusres,
  typeaheadSearchresult:state.apiData.typeaheadSearchresult,
  reviewerData: state.apiData.reviewerData,
});
function mapDispatchToProps(dispatch) {
  return {
    userLogoutAction: bindActionCreators(userLogoutAction, dispatch),
    getNotifications: bindActionCreators(getNotifications, dispatch),
    updateReadStatus: bindActionCreators(updateReadStatus, dispatch),
    getTypeaheadSearchResult: bindActionCreators(getTypeaheadSearchResult, dispatch),
    getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
    setSelectedOtherCompanyInfoAction: bindActionCreators(setSelectedOtherCompanyInfoAction, dispatch),
    getReviewBycompanyidAction: bindActionCreators(getReviewBycompanyidAction, dispatch),

  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
