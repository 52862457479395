const defaultImageUrls = {
    "user": "/images/user-default-image.png",
    "companyLogo": "/images/company-default-logo.jpg",
    "companyBg": "/images/company-default-background-image.jpg",
    "companyCover": "/images/company-default-cover-image.jpg",
    "userCover": "/images/company-default-cover-image.jpg"
}

const companyFormType = {
    1: "company_details",
    2: "address_details",
    3: "team_details",
    4: "founder_details",
    5: "field_visibility",
    6: "update_details"
}

const constants = {
    defaultImageUrls,
	companyFormType
}
export default constants;