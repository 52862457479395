export const url = {
    // auth
    generateToken: 'auth/generateToken',
    loginByEmail: 'access/generateToken',
    loginBySocial: 'access/generateToken',
    //verifyOtp: 'account/verify/otp',
    verifyOtp: 'access/generateToken',
    signupWithEmail: 'account/signup',
    sendOtp: 'account/send/otp',
    verifyResetOtpPassword: 'account/otpverify/generateverifytoken',
    resendOtp: 'account/resend/otp',
    resetPassword: 'account/change/password/verify',
    getUserByAccountId: 'account/user?account_id=:account_id',
    saveCompanyDetails: 'save/companydetails',
    saveInterest: 'account/user/profile',
    getCompanyDetails: 'companydetails',

    addNewPost: 'cpms/company/post/create',
    editPost: 'cpms/company/post/update',
    deletePost: 'cpms/company/post/delete',
    getPostList: 'cpms/company/post/detail',
    getFollowedCompanyPostList: 'cpms/post/details',
    getPostComments: 'cpms/company/post_comment/detail?',
    likeDislikePost: 'cpms/company/post/like',
    addNewPostComment: 'cpms/company/post/comments',
    getCompanyList: 'get/usercompanies',
    followCompany: 'follow/detail',
    getCompanySuggestions: 'cgss/companies/suggestions',
    sentConnectionRequest: 'connection/request',
    blockRequest: 'block/account',
    unBlockRequest: 'remove/block',

    // ----updated by ashis----
    saveRating: 'rs/company/addReview',
    getReviewByCompanyId: 'rs/company/getReview',
    getConnectionStatus: 'connection',
    editPostComments: 'cpms/company/post/updatePostComment',
    deletePostComments:'cpms/company/post/deletePostComment',
    pokeRequest: 'poke',
    pokeDetails: 'poke/detail',
    typeaheadSearch:'cgss/typeahead/search',
    


    // ----updated by chetna joshi----
    getConnectedCompanies: 'get/company/connection',
    updateConnectionRequest: 'connection/request',
    getBlockAccounts: 'block/detail',
    deleteFounderTeam: 'delete/member',
    addFirebaseToken: 'notification/firebase/token',
    getNotifications: 'notification/notification/log',
    updateReadStatus:'notification/notification/log/read/status'
}