import React from "react";
import { Link } from "react-router-dom";
import Header from "../../SubComponents/Header/Header";
import Footer from "../../SubComponents/Footer/Footer";
const companyOptions = props => (
  <div>
    <Header {...props} headerType="companyOptions"/>
    <main className="mainBody container-fluid company_opt_main">
  <div className="row height-100">
    <div className="container height-100">
      <div className="row no-gutters height-100 d-flex justify-content-center align-items-center">
        <ul className="imageOptions">
          <li>
            <Link to="create-company">
              <img alt="" src="images/office.svg" />
              <p>Create Company</p>
            </Link>
          </li>
          <li>
            <Link to="/user">
              <img alt="" src="images/skip.svg" />
              <p>Skip </p>
            </Link>
          </li>
         
        </ul>
      </div>
    </div>
  </div>
</main>

    <Footer {...props} />
  </div>
);

export default companyOptions;
