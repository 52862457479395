import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import Helper from "../../../../Configs/Helper";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'

class FormStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      address: ''
    }
  }

  render() {
    return (
      <div className="box-body">
  <ul className="deailsList">
    <li>
      <div>
        <div className="label">Company Name <span className="required_field">*</span></div>
        <div className="details">
          <input type="text" maxLength="50" className="form-control" name="s1_company_name" onChange={this.props.onChnageHandler} value={this.props.state.s1_company_name}/>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.company_name}</span>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Company Sub Title <span className="required_field">*</span></div>
        <div className="details">
          <input type="text" maxLength="60" className="form-control" name="s1_company_sub_title" onChange={this.props.onChnageHandler} value={this.props.state.s1_company_sub_title}/>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.company_sub_title}</span>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Company Type <span className="required_field">*</span></div>
        <div className="details">
          <select className="form-control" name="s1_company_type" onChange={this.props.onChnageHandler} value={this.props.state.s1_company_type}>
            <option value="">--Select--</option>
            <option>Public</option>
            <option>Self-Employed</option>
            <option>Government</option>
            <option>Agency</option>
            <option>Nonprofit</option>
            <option>Sole Propertiership</option>
            <option>Private held</option>
            <option>Partnership</option>
          </select>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.company_type}</span>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">E-mail Address <span className="required_field">*</span></div>
        <div className="details">
          <input type="text" className="form-control" name="s1_email_id" onChange={this.props.onChnageHandler} value={this.props.state.s1_email_id}/>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.email_id}</span>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Phone No</div>
        <div className="details">
        <select className="form-control" name="s1_dial_code" onChange={this.props.onChnageHandler} value={this.props.state.s1_dial_code} style={{display:"initial",width: "60%"}}>
            <option value="">--select--</option>
            {Helper.getDropdownsVals('DialCodes').map((value, key) => <option key={key}>{`${value.name} (${value.dial_code})`}</option>)}
          </select>
          <input type="text" className="form-control" name="s1_phone_num" onChange={this.props.onChnageHandler} value={this.props.state.s1_phone_num} style={{display:"initial",width: "40%"}}/>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Description <span className="required_field">*</span></div>
        <div className="details">
          <textarea className="form-control" name="s1_description" onChange={this.props.onChnageHandler} value={this.props.state.s1_description}/>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.about_company}</span>
        </div>
      </div>
    </li>
    <li className="imageContainer">
      <div>
        <div className="label">Company Logo</div>
        <div className="details companyLogo">
          <div className="upload-options">
            <input type="file" className="image-upload form-control-file" accept="image/*" name="s1_company_logo" onChange={this.props.onChnageHandler} />
          </div>
          <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
          <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
          </div>

          <div id="coverImgContainer" className="image-preview" style={{backgroundImage: 'url('+this.props.state.s1_company_logo_preview+')'}}/>
          {/* src={this.props.state.s1_company_logo_preview} */}
        

        </div>
        
      </div>
    </li>
    <li>
      <div>
        <div className="label">Year Founded</div>
        <div className="details">
          <select className="form-control" name="s1_year_founded" onChange={this.props.onChnageHandler} value={this.props.state.s1_year_founded}>
          <option value="">--select--</option>
            {Helper.getDropdownsVals('Years').map((value, key) => <option key={key}>{value.year}</option>)}
          </select>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Industry Type <span className="required_field">*</span></div>
        <div className="details">
          <select className="form-control" name="s1_industry_type" onChange={this.props.onChnageHandler} value={this.props.state.s1_industry_type}>
            <option value="">--select--</option>
            {Helper.getDropdownsVals('CompanyIndustries').map((value, key) => <option key={key}>{value.name}</option>)}
          </select>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.industry_type}</span>
      </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Company Size <span className="required_field">*</span></div>
        <div className="details">
        <select className="form-control" name="s1_company_size" onChange={this.props.onChnageHandler} value={this.props.state.s1_company_size}>
            <option value="">--Select--</option>
            <option>0-10</option>
            <option>11-50</option>
            <option>51-100</option>
            <option>101-500</option>
            <option>500 & Above</option>
          </select>
          <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.company_size}</span>
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Specialization</div>
        <div className="details">
          <TagsInput value={this.props.state.s1_specialization} onChange={this.props.handleChangeSpecialization} />
          {/*<input type="text" className="form-control" data-role="tagsinput" name="s1_specialization" onChange={this.props.onChnageHandler}/>*/}
        </div>
      </div>
    </li>
    <li>
      <div>
        <div className="label">Website url</div>
        <div className="details">
          <input className="form-control" name="s1_website_url" onChange={this.props.onChnageHandler} value={this.props.state.s1_website_url}/>
        </div>
      </div>
    </li>
    <li>
    
      <div class="form-check">
      <input type="checkbox" class="form-check-input" 
            name="s1_i_agree" id="Checkform" value={this.props.state.s1_i_agree} onClick={this.props.onClickHandler}/>
      <label class="form-check-label" for="CheckForm">I verify that I am an authorized representative of this organization and have the right to act on its behalf in the creation and management of this page. The organization and I agree to the additional terms for Pages.</label>
      </div>
      <span className="errorMsg errorStepForm">{this.props.state.companyInfoFormErrors.i_agree}</span>
    </li>
    
    {/* <li>
      <div>
        <div className="label">Comrate public url</div>
        <div className="details">
          <input type="text" className="form-control" name="s1_public_url" onChange={this.props.onChnageHandler}/>
        </div>
      </div>
    </li> */}
  </ul>
</div>

    );
  }
}

export default (FormStep1);
