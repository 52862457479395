import React, { Component } from "react";
// import Helper from "../../../Configs/Helper";
import './style.css'
import { makeStyles, Theme} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Button from '@material-ui/core/Button';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';

import clsx from 'clsx';
//import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import ErrorIcon from '@material-ui/icons/Error';
//import InfoIcon from '@material-ui/icons/Info';
//import CloseIcon from '@material-ui/icons/Close';
import { amber, green } from '@material-ui/core/colors';
// import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
//import WarningIcon from '@material-ui/icons/Warning';

const useStyles1 = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#dc3545',
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default class FormErrors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      alertOpen: false,
      alertType:'',
      alertTitle: 'Alert !',
      alertMsg: ''
    };
    this.show = this.show.bind(this);
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  show(status) {
    this.setState({ loader: status });
  }
  showAlert(status,type,msg){
    this.setState({ 
      alertOpen: status,
      alertType: type,
      //alertTitle: '',
      alertMsg: msg
      });
    //setTimeout(this.handleClose,8000);
  }
  handleClose = () => {
    
    this.setState({ alertOpen: false  });
  };
  render() {
    
    if(this.state.loader){
      return (
        <div className="showbox">        
          <div className="loader">
            <CircularProgress />
          </div>
        </div>
      )
    } else if(this.state.alertOpen){
      return <div>
       <MySnackbarContentWrapper
        variant={this.state.alertType}
        //className={classes.margin}
        open={this.state.alertOpen}
        title={this.state.alertTitle}
        message={this.state.alertMsg}
        handleClose={this.handleClose}
      />
    </div>
    } else {
      return null;
    }
  }
}

function MySnackbarContentWrapper(props: Props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, open, ...other } = props;
  //const Icon = variantIcon[variant];

  return (
    <Snackbar
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={3000}
    onClose={props.handleClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
>
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          {/* <Icon className={clsx(classes.icon, classes.iconVariant)} /> */}
          {message}
        </span>
      }
     
      {...other}
    />
    </Snackbar>
  );
}