import React, { Component } from "react";
import HomeHeader from "../../SubComponents/HomeHeader/HomeHeader";
import Footer from "../../SubComponents/Footer/Footer";
import LoginModal from "../../Modals/LoginModal/LoginModal";
import SignupModal from "../../Modals/SignupModal/SignupModal";
import Helper from "../../../Configs/Helper";
import Validation from "../../../Configs/Validation";
import Loader from "../../SubComponents/Loader/Loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { generateTokenAction, registerByEmailAction, otpVerifyAction, resendOtpAction, loginByEmailAction, getUserByAccountIdAction, loginBySocialAction, getCompanyDetailsByIdAction, setSelectedOwnCompanyInfoAction } from "../../../store/actions";
import $ from "jquery";
import './css/styleWeb.css';
//import Firebase from "../Firebase/Firebase";

const timeSec = 600;
const timeObj = {h: '00', m: '00', s: '00'};
let interval='';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenLoginModal: false,
      isOpenModal: false,
      isSignupModal: false,
      isLoginModal: false,
      isOtpModal: false,
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      conf_password: "",
      otp_code: "",
      otpSessionId: "",
      resendOtpBtn: false,
      timeCount: timeSec,
      timeObj : timeObj,
      signupFormErrors: {firstname: '', lastname: '', email: '', password: '', conf_password: ''},
      signupFormValid: false,
      loginFormErrors: {email: '', password: ''},
      loginFormValid: false
    };
    this.onChnageSignupFormHandler = this.onChnageSignupFormHandler.bind(this);
    this.onChnageLoginFormHandler = this.onChnageLoginFormHandler.bind(this);
    this.onChnageHandler = this.onChnageHandler.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.signUpByEmail = this.signUpByEmail.bind(this);
    this.loginByEmail = this.loginByEmail.bind(this);
    this.getUserDetails = this.getUserDetails.bind(this);
    this.otpVerifyHandler = this.otpVerifyHandler.bind(this);
    this.otpResendHandler = this.otpResendHandler.bind(this);
    this.startResendOtpTimer = this.startResendOtpTimer.bind(this);
    this.signupLoginBySocial = this.signupLoginBySocial.bind(this);
  }
  componentDidMount(){
  }
  startResendOtpTimer() {
     interval = setInterval(() => {
      let time = this.state.timeCount;
      this.setState({ timeCount: time - 1 }, () => {
        let timeObj = Helper.secondsToTime(this.state.timeCount);
        this.setState({timeObj});
        if (this.state.timeCount === 0) {
          clearInterval(interval);
          this.setState({ resendOtpBtn: true });
        }
      });
    }, 1000);
  }
  onChnageSignupFormHandler(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value },() => { 
      const res = Validation.signupForm(name, value,this.state);
      this.setState({ signupFormValid:res.formValid, signupFormErrors: res.formErrors }); 
    });
  }
  onChnageLoginFormHandler(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value },() => { 
      const res = Validation.loginForm(name, value,this.state);
      this.setState({ loginFormValid:res.formValid, loginFormErrors: res.formErrors }); 
    });
  }

  onChnageHandler(event) {
    if(event.target.name==='otp_code'){
      this.setState({otp_code:event.target.value.replace(/\D/,'')});
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  async loginByEmail(){
    if(!this.state.loginFormValid){
      this.loader.showAlert(true,'error',Helper.flashMsg('requireFields'))
      return;
    }
    const generateTokenPostData = {
      client_id: "",
      client_type: "web",
      email_id: this.state.login_email,
      auth_type: "email"
    };
    this.loader.show(true);

    /* start generate login token */
    await this.props.generateTokenAction(generateTokenPostData);
    if(!this.props.tokenData.error){

      /* start api login by email */
      const postData = {
        client_id: "",
        client_type: "web",
        auth_type: "email",
        email_id: this.state.login_email,
        password: this.state.login_password,
        //account_id: '5da31cf03c0c500001146871'
      };
      await this.props.loginByEmailAction(postData);
      
      if(this.props.loginData.status===200){
        //this.loader.showAlert(true,'success',this.props.loginData.message)

        /* start api get user data */
        const { account_id, company_profiles } = this.props.loginData.data;
        await this.props.getUserByAccountIdAction(account_id);
        if(this.props.userData.status===200){
          //firebase notification init
          this.fcmRegistration();

          //this.loader.showAlert(true,'success',Helper.flashMsg('loginSuccess'));
          //const { user_account_data, user_profile_data, company_profiles} = this.props.userData.data;
         
          // check user company exist or not
          let route = '';
          if(company_profiles!==null && company_profiles!==undefined){
            if(company_profiles.length>0){
              route = 'newsfeed';
            }
          } 

          if(route===''){
            this.props.history.push('/company-options');
          } else {
            this.setDefaultCompanyIdAndData();
            //this.props.history.push('/news-feed');
          }
          
        } else {
          this.loader.showAlert(true,'error',this.props.userData.message)
        }
        /* end api get user data */

      } else {
       
        // if email not verified
        if(this.props.loginData.status===403){
         if(this.props.loginData.data){
            if(!this.props.loginData.data.is_email_verified){
              this.closeModal('loginModal');
              this.openModal('signUpModal');
              this.setState({ isOtpModal: true, isSignupModal: false, email: this.state.login_email, otpSessionId: this.props.loginData.data.otp_session_id, accountId: this.props.loginData.data.id});
              this.startResendOtpTimer();
              setTimeout(function() {
                $(".modal-dialog").css("max-width", "100%");
                $(".modal-dialog").css("margin", "0");
                $(".modal-content").css("overflow", "hidden");
                $(".modal").css("overflow", "hidden");
                $(".modal-body").css("padding", "0");
              }, 1);
            }
         } 
        }
        this.loader.showAlert(true,'error',this.props.loginData.message);
        //this.loader.showAlert(true,'error',this.props.loginData.message)
      }
      /* end api login by email */

    } else {
      this.loader.showAlert(true,'error',this.props.tokenData.message)
    }
     /* end generate login token */
    this.loader.show(false);
  }
  async signUpByEmail() {
    if(!this.state.signupFormValid){
      this.loader.showAlert(true,'error',Helper.flashMsg('requireFields'))
      return;
    }
    const generateTokenPostData = {
      client_id: "",
      client_type: "web",
      email_id: this.state.email,
      auth_type: "email"
    };
    this.loader.show(true);
    await this.props.generateTokenAction(generateTokenPostData);
    if(this.props.tokenData.status===200){
        //signup api call
        const postData = {
          first_name: this.state.firstname,
          last_name: this.state.lastname,
          email_id: this.state.email,
          password: this.state.password,
          auth_type: 'email',
          client_id: '',
          client_type: 'web',
        };
        
        await this.props.registerByEmailAction(postData);
        
        if(this.props.signupData.status===200){
          this.setState({isSignupModal: false, isOtpModal: true, otpSessionId: this.props.signupData.data.otp_session_id, accountId: this.props.signupData.data.id});
          this.startResendOtpTimer();
          //this.loader.showAlert(true,'success',this.props.signupData.message)
        } else {
          this.loader.showAlert(true,'error',this.props.signupData.message)
        }       
    } else {
      this.loader.showAlert(true,'error',this.props.tokenData.message)
    }
    this.loader.show(false);
  }

  async getUserDetails(){

  }
  async otpVerifyHandler(){
    if(this.state.otp_code===''){
      this.loader.showAlert(true,'error',Helper.flashMsg('otpRequiredErr'))
      return;
    } else if(this.state.otp_code.length<6 || this.state.otp_code.length>6){
      this.loader.showAlert(true,'error',Helper.flashMsg('otpLength'))
      return;
    }
    //const { data } = this.props.signupData;
    const postData={
      otp: this.state.otp_code,
      password: this.state.password,
      account_id: this.state.accountId,
      email_id: this.state.email,
      client_id: "",
      client_type: "web",
      otp_session_id: this.state.otpSessionId,
      auth_type: "email"
    }
    this.loader.show(true);
    await this.props.otpVerifyAction(postData);
    if(this.props.otpData.status===200){
      this.loader.showAlert(true,'success',Helper.flashMsg('signupSuccess'))
        /* start api get user data */
        console.log(this.props,'PROPS....');
        
        let id = '';
        if(this.props.signupData===null){
          id = this.props.loginData.data.id
        } else {
          id = this.props.signupData.data.id
        }
        await this.props.getUserByAccountIdAction(id);
        if(this.props.userData.status===200){
          //firebase notification init 
          this.fcmRegistration();

          //this.loader.showAlert(true,'success',this.props.userData.message)
          const { user_account_data, user_profile_data, company_profiles} = this.props.userData.data;
          this.props.history.push('/interest');
          // if(company_profiles===null){
          //   this.props.history.push('/company-options');
          // } else {
          //   this.props.history.push('/news-feed');
          // }
        } else {
          this.loader.showAlert(true,'error',this.props.userData.message)
        }
        /* end api get user data */
       
    } else {
      this.loader.showAlert(true,'error',this.props.otpData.message)
    }
    this.loader.show(false);
  }

  async otpResendHandler(){
    this.setState({ resendOtpBtn: false,timeCount: timeSec, timeObj : timeObj, });
    clearInterval(interval);
    const { data } = this.props.signupData;
    const postData={
      account_id: data.id,
      email_id: this.state.email,
      client_id: "",
      client_type: "web",
      auth_type: "email"
    }
    this.loader.show(true);
    let res = await resendOtpAction(postData);
   
    if(res.otpResendData){      
      if(res.otpResendData.status===200){
        this.setState({ otpSessionId: res.otpResendData.data.otp_session_id });
        this.startResendOtpTimer();
        this.loader.showAlert(true,'success',res.otpResendData.message)
      } else {
        this.loader.showAlert(true,'error',res.otpResendData.message)
      }
    } else {
      this.loader.showAlert(true,'error',Helper.flashMsg('apiResErr'))
    }
    this.loader.show(false);
    console.log(this.props,'otpResend',res);
   
  }
  async signupLoginBySocial(res, type){
    console.log(res.error,res, type);
    let socialData;
    if(res.error!==undefined){
      return;
    }
     if (type === 'facebook') {
        if(res.email){
            socialData = {
              name: res.name,
              provider: 'fb',
              email: res.email,
              provider_id: res.id,
              accessToken: res.accessToken,
              token: res.accessToken,
              provider_pic: res.picture.data.url,
        };
      }
    
    }

    if (type === 'google' && res!==undefined) {
      if (res.profileObj===undefined) return;
      if (res.tokenObj===undefined) return;
      socialData = {
        name: res.profileObj.name,
        provider: type,
        email: res.profileObj.email,
        provider_id: res.profileObj.googleId,
        access_token: res.tokenObj.access_token,
        token: res.tokenObj.id_token,
        provider_pic: res.profileObj.imageUrl
      };
    }
    
    if (socialData) {
      const postData = {
        first_name: socialData.name,
        last_name: '',
        email_id: socialData.email,
        password: '',
        auth_type: socialData.provider,
        client_id: '',
        client_type: 'web',
        token: socialData.token
      };
      const generateTokenPostData = {
        client_id: "",
        client_type: "web",
        email_id: socialData.email,
        auth_type: "email"
      };

      console.log(res.error,socialData,postData,'res, type');
      this.loader.show(true);
      await this.props.generateTokenAction(generateTokenPostData);
      if(!this.props.tokenData.error){
         /* start api login by social */
        await this.props.loginBySocialAction(postData);
        if(this.props.loginData.status===200){
          //this.loader.showAlert(true,'success',this.props.loginData.message)

          /* start api get user data */
          //let accId = (this.props.loginData) ? this.props.loginData.data.account_id : '';
          const { account_id, company_profiles } = this.props.loginData.data;
          await this.props.getUserByAccountIdAction(account_id);
          if(this.props.userData.status===200){
            //firebase notification init 
            this.fcmRegistration();

            //this.loader.showAlert(true,'success',this.props.userData.message)
            //const { user_account_data, user_profile_data, company_profiles} = this.props.userData.data;
            // check user company exist or not
            if(company_profiles===null){
              this.props.history.push('/interest');
            } else {
              this.setDefaultCompanyIdAndData()
            }
          } else {
            this.loader.showAlert(true,'error',this.props.userData.message)
          }
          /* end api get user data */

        } else {
          this.loader.showAlert(true,'error',this.props.loginData.message)
        }
      /* end api login by social */
      }
      this.loader.show(false);
    } else {
      alert('Response Error !');
    }
  }
  openModal(modalType) {
    this.setState({ resendOtpBtn: false,timeCount: timeSec, timeObj : timeObj, });
    clearInterval(interval);
    if (modalType === "loginModal") {
      this.setState({ isOpenLoginModal: true, isLoginModal: true, isOpenModal: false, isSignupModal: false });
    } else if (modalType === "signUpModal") {
      this.setState({ isOpenLoginModal: false, isLoginModal: false, isOpenModal: true, isSignupModal: true });
      //this.setState({isSignupModal: false, isOtpModal: true, otpSessionId: 1, accountId: 1});
    } else if (modalType === "otp") {
      this.setState({ isOtpModal: true, isSignupModal: false });
    }
  }
  closeModal(modalType) {
    if (modalType === "loginModal") {
      this.setState({ isOpenLoginModal: false, isLoginModal: false });
    } else if (modalType === "signUpModal") {
      this.setState({ isOpenModal: false, isSignupModal: false });
    } else if (modalType === "otpModal") {
      this.setState({ isOpenModal: false, isOtpModal: false });
    }
  }

  setDefaultCompanyIdAndData = async () =>{
    //set default company id and data
      const { account_id, company_profiles } = this.props.userData.data
      if(company_profiles!==null && company_profiles!==undefined ){
        if(company_profiles.length>0){
            //set default company id in local storage
            Helper.setDefaultCompany(company_profiles[0],'user_company');

            const paramsData = {  account_id: account_id, company_id: company_profiles[0] }
            await this.props.getCompanyDetailsByIdAction(paramsData);
            if(this.props.companyDetails.status===200){
                await this.props.setSelectedOwnCompanyInfoAction(this.props.companyDetails);
                this.props.history.push('/news-feed');
            }
        }
      } else {
        this.props.history.push('/news-feed');
      }
  }

  fcmRegistration(){
    //this.firebase.fcmConfiguration()();
  }

  render() {
   
    //console.log(this.props);
    return (
      <div>
        {/* Header Start */}
        <HomeHeader openModal={this.openModal} headerType="home" {...this.props} />
        {/* Header End */}

      <main className="mainBody container-fluid home_main">
  {/* Banner Section Start*/}      
  <section>
  <div className="row section" id="banner" >
    <div className="container h-100">
      <div className="row align-items-center h-100">
        <div className="col-8" data-aos="fade-right" data-aos-duration={1500}>
          <div>
            <h1 className="title-lg">ComRate builds trust  into business.</h1>
            {/* <div className="input-group input-group-lg">
              <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Recipient's username" />
              <div className="input-group-append">
                <button className="btn btn-danger" type="button" id="get_started">Get Started Today</button>
              </div>
            </div> */}
           
          </div>
          <div className="input-group input-group-lg">
              <input type="text" className="form-control" placeholder="Enter your email address" aria-label="Recipient's username" />
              <div className="input-group-append">
                <button className="btn btn-danger" type="button" id="get_started">Get Started Today</button>
              </div>
            </div>
            <p>
              " A company that pays late doesn't always go bust. <br />
              a company that goes bust almost always pays late. "
            </p>
        </div>
        <div className="col-4" />
      </div>
    </div>
  </div>
  </section>
  {/* Banner Section End*/}
  
  

      {/* Community Section Start*/}
      <section>
      <div className="h_sec2 container-fluid section " id="community_section">
        <div className=" container h-100" >
          <div className="row align-items-center h-100">
            <div className="col-5" />
            <div className="col-7 text-right" data-aos="fade-left" data-aos-duration={1500}>
              <div className="box bg-transparent">
                <div className="" style={{marginBottom:'0'}}>
                  <h2 className="title-lg">Company Ratings - The Community Way </h2>
                </div>
                <div className="box-body">
                  <p>
                    " ComRate combines traditional credit scoring with the power of big data, crowdsourcing, and social media, to provide the most accurate ratings possible.  Rate your customers, suppliers or even your own business. "
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
  {/* Community Section End*/}
  {/* section circle */}
      {/* <section>
        <div className="h_sec3">
          <div className="container-fluid">
            <div className="row h_sec3row">
              <div className="h_3heading col-sm-12" data-aos="fade-up" data-aos-duration={1000}>
                Comrate Communication Steps
              </div>
              <div className="col-sm-10 h_3dots">
                <div className="h_3dotslist" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={800}>
                  <div className="h_3dotslistitem">
                    <span><i className="fa fa-users" aria-hidden="true"></i></span>
                  </div>
                  <div className="h_3dotslistname">
                    Create Company
                  </div>
                </div>

                <div className="h_3dotslist h_3spacedots" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={800}> </div>


                <div className="h_3dotslist" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1200}>
                  <div className="h_3dotslistitem">
                    <span><i className="fa fa-paper-plane-o" aria-hidden="true"></i></span>
                  </div>
                  <div className="h_3dotslistname">
                    Send Post
                  </div>
                </div>

                <div className="h_3dotslist h_3spacedots" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1200}> </div>

                <div className="h_3dotslist" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1600}>
                  <div className="h_3dotslistitem">
                    <span><i className="fa fa-comments" aria-hidden="true"></i></span>
                  </div>
                  <div className="h_3dotslistname">
                    Communication
                  </div>
                </div>

                <div className="h_3dotslist h_3spacedots" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1600}></div>

                <div className="h_3dotslist" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={2000}>
                  <div className="h_3dotslistitem">
                    <span><i className="fa fa-star-half-o" aria-hidden="true"></i></span>
                  </div>
                  <div className="h_3dotslistname">
                    Company Rating
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section> */}
  {/* section circle end */}

  
  {/* Third Section Start*/}
  <div className=" h_5sec row bg-grey ">
    <div className="container-fluid " id="withCom">
      <div className="row align-items-center ">
        <div className="col-12 text-center">
          <div className="">
            <div className=" " data-aos="fade-up" data-aos-duration={1000}>
              <div className="h_3heading">With ComRate You Can</div>
            </div>
            <div className="box-body">
              <div className="blocks block_3">
                <div className="block" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={800}>
                  <div className="inner">
                    <div className="icon">
                      <img alt="" src="images/2.svg" />
                    </div>
                    <div className="details with_p">
                      <h4 className="heading">Score Your Customers</h4>
                      <p style={{fontSize:'14px'}}>
                        Score your customers and answer that all important question:  When will we get paid?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="block" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1200}>
                  <div className="inner">
                    <div className="icon">
                      <img alt="" src="images/3.svg" style={{paddingTop:'12px'}} />
                    </div>
                    <div className="details with_p">
                      <h4 className="heading">Score Yourself</h4>
                      <p style={{fontSize:'14px'}}>
                        Score yourself and discover how others view your credit worthiness.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="block" data-aos="flip-left" data-aos-duration={1000} data-aos-delay={1600}>
                  <div className="inner">
                    <div className="icon">
                      <img alt="" src="images/1.1.svg" style={{paddingTop:'10px'}} />
                    </div>
                    <div className="details with_p">
                      <h4 className="heading">Score Your Suppliers</h4>
                      <p style={{fontSize:'14px'}}>
                        Score your suppliers and have the peace of mind that they can deliver.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Third Section End*/}
  {/* Four Section Start*/}
  <div className="row  bg-red default-block ">
    <div className="container h-100">
      <div className="row align-items-center h-100">
        <div className="col-3">
          <h2 className="title text-white" style={{margin: '0px'}}> Get Informed Today. </h2>
        </div>
        <div className="col-9 text-right">
          <button className="btn btn-lg btn-outline-light"> Sign Up - $14.99/Report </button>
        </div> 
      </div>
    </div>
  </div>
  {/* Four Section End*/}
</main>

        {/* login modal Start */}
        <LoginModal
          state={this.state}
          onChnageHandler={this.onChnageLoginFormHandler}
          loginByEmail={this.loginByEmail}
          signupLoginBySocial={this.signupLoginBySocial}
          openModal={this.openModal}
          closeModal={this.closeModal}
          {...this.props}
        />
        {/* login modal End */}

        {/* signup modal Start */}
        <SignupModal
          state={this.state}
          onChnageOtp={this.onChnageHandler}
          otp_code={this.state.otp_code}
          onChnageHandler={this.onChnageSignupFormHandler}
          signUpByEmail={this.signUpByEmail}
          signupLoginBySocial={this.signupLoginBySocial}
          otpResendHandler={this.otpResendHandler}
          otpVerifyHandler={this.otpVerifyHandler}
          openModal={this.openModal}
          closeModal={this.closeModal}
          {...this.props}
        />
        {/* signup modal End */}

        {/* footer Start */}
        <Footer {...this.props} />
        {/* footer End */}

        {/* loader Start */}
          <Loader onRef={ref => {this.loader = ref;}}/>
        {/* loader Start */}
        {/* <Firebase onRef={ref => {this.firebase = ref;}}/> */}
        
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  tokenData: state.auth.tokenData,
  signupData: state.auth.signupData,
  otpData: state.auth.otpData,
  companyDetails: state.appData.companyDetails,
  ownCompanyDetails: state.appData.ownCompanyDetails,
  loginData: state.auth.loginData,
});

function mapDispatchToProps(dispatch) {
  return {
    generateTokenAction: bindActionCreators(generateTokenAction, dispatch),
    registerByEmailAction: bindActionCreators(registerByEmailAction, dispatch),
    otpVerifyAction: bindActionCreators(otpVerifyAction, dispatch),
    loginByEmailAction: bindActionCreators(loginByEmailAction, dispatch),
    getUserByAccountIdAction: bindActionCreators(getUserByAccountIdAction, dispatch),
    loginBySocialAction: bindActionCreators(loginBySocialAction, dispatch),
    getCompanyDetailsByIdAction: bindActionCreators(getCompanyDetailsByIdAction, dispatch),
    setSelectedOwnCompanyInfoAction: bindActionCreators(setSelectedOwnCompanyInfoAction, dispatch),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
