import React from 'react';
import Constants from '../../../utils/Constants';

const BUCKET_PATH = process.env.REACT_APP_BUCKET_PATH;
const USER_DEFAULT_IMAGE = Constants.defaultImageUrls.user;

class Notification extends React.Component{
  constructor(props){
    super(props);
   this.state={ }
 }

  
render (){

  return (
    <ul>
        {
            this.props.notificationDetails!==null && this.props.notificationDetails.status===200 ?
            this.props.notificationDetails.length === 0 ?
                <li>
                    <span>No Notifications.</span>
                </li>
                :
                this.props.notificationDetails.map((notification, index) => (

                    <li className="unread" key={index}>
                        <a href="#">
                            <div className="img">
                                <img alt="" src={notification.bg_payload.image == null ? USER_DEFAULT_IMAGE : (BUCKET_PATH + notification.bg_payload.image)} />
                                <i className="fa fa-user icon bg-dark"></i>
                            </div>
                            <div className="details">
                                <p>{notification.message_body}</p>
                                <span className="time">{notification.notification_time}</span>
                            </div>
                        </a>
                    </li>
                ))
       :<li>
       <span>No Notifications.</span>
   </li> }
    </ul>
)
}
}
export default Notification