import React, { Component } from "react";
// import Helper from "../../../Configs/Helper";
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
export default class FormErrors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const responseFacebook = (response) => {
      this.props.signupLoginBySocial(response, 'facebook');
    }  
    const responseGoogle = (response) => {
      this.props.signupLoginBySocial(response, 'google');
    }
    return (
      <>
      <FacebookLogin
      appId="550632825687827" //APP ID NOT CREATED YET
      fields="name,email,picture"
      callback={responseFacebook}
      cssclassName="btn btn-facebook"
      icon="fa-facebook"
      //autoLoad={true}
    />
    &nbsp;&nbsp;&nbsp;&nbsp;
    <GoogleLogin
      clientId="747759596708-2upu687ca2iburmqag6pruq8spflp518.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
      buttonText="LOGIN WITH GOOGLE"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      className="btn btn-google"
      icon={false}
      disabledStyle={{backgroundColor: 'transparent', display: 'inline-flex', alignItems: 'center', color: '#fff',
       boxShadow: '', padding: '0px', borderRadius: '0px', border: '0px', fontSize: '14px', fontWeight: 500, fontFamily: ''}}
      >
      <i className="fa fa-google" /> Login with Google 
      </GoogleLogin>
      </>
    )
  }
}