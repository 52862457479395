import React from 'react';
import Helper from './Helper';
function emailRegix(email){
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}
function validString(string){
  return string.match( /^[a-zA-Z\s]+$/); 
}

function isEmailValid(value) {
  let msg = '';
  let emailValid = emailRegix(value);
  if(value.length===0){
    msg = Helper.flashMsg('emailReq');
  } else if(!emailValid){
    msg = Helper.flashMsg('emailNotValid');
  }
  return msg;
}
function signupForm(fieldName, value, state) {
  let fieldValidationErrors = state.signupFormErrors;
  let emailValid = false;
  let passwordValid = false;
  let formValid = false;
  let valLength = value.length;
  let msg = '';
 switch(fieldName) {
      case 'email':
        msg = '';
        emailValid = emailRegix(value);
        if(valLength===0){
          msg = 'Email is required';
        } else if(!emailValid){
          msg = 'Please enter valid email address';
        }
        fieldValidationErrors.email = msg;
        break;
      case 'password':
          msg = '';
        if(valLength===0){
          msg = 'Password is required';
        } if(valLength<=5){
          msg = 'Password is too short';
        } 
        fieldValidationErrors.password = msg;
        break;
      case 'conf_password':
          msg = '';
          if(valLength===0){
            msg = 'Confirm password is required';
          } if(valLength<=5){
            msg = 'Password is too short';
          } else if(state.password!==value){
            msg = 'Password and confirm password not match';
          }
        fieldValidationErrors.conf_password = msg;
        break;
      case 'firstname':
          msg = '';
          if(valLength===0){
            msg = 'Firstname is required';
          } else if(validString(value)===null){
            msg = 'Firstname is invalid';
          }
        fieldValidationErrors.firstname = msg;
        break;
      case 'lastname':
          msg = '';
          if(valLength===0){
            msg = 'Lastname is required';
          } else if(validString(value)===null){
            msg = 'Lastname is invalid';
          }
         
        fieldValidationErrors.lastname = msg;
        break;
      default:
        break;
    }
    if( 
      (state.email!=='' && state.email!==undefined) && 
      (state.password!=='' && state.password!==undefined) && 
      (state.conf_password!=='' && state.conf_password!==undefined) && 
      (state.firstname!=='' && state.firstname!==undefined) && 
      (state.lastname!=='' && state.lastname!==undefined) && 
      msg===''){
      formValid = true;
    }
  return { formValid: formValid, formErrors: fieldValidationErrors };
}

function loginForm(fieldName, value, state) {
  let fieldValidationErrors = state.signupFormErrors;
  let emailValid = false;
  let passwordValid = false;
  let formValid = false;
  let valLength = value.length;
  let msg = '';
    switch(fieldName) {
      case 'login_email':
        msg = '';
        emailValid =emailRegix(value);
        if(valLength===0){
          msg = Helper.flashMsg('emailReq');
        } else if(!emailValid){
          msg = Helper.flashMsg('emailNotValid');
        }
        fieldValidationErrors.email = msg;
        break;
      case 'login_password':
          msg = '';
        if(valLength===0){
          msg =  Helper.flashMsg('passReq');
        } if(valLength<=5){
          msg = Helper.flashMsg('passShort');
        } 
        fieldValidationErrors.password = msg;
        break;
        default:
        break;
    }
  
  if( (state.login_email!=='' && state.login_email!==undefined) && (state.login_password!=='' && state.login_password!==undefined) && msg===''){
    formValid = true;
  }
  return { formValid: formValid, formErrors: fieldValidationErrors };
}
function companyInfoStep(fieldName, value, state) {
  let fieldValidationErrors = state.companyInfoFormErrors;
  let formValid = false;
  let emailValid = false;
  let valLength = value.length;
  let msg = '';
  
    switch(fieldName) {
      case 's1_company_name':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_name_req');
        fieldValidationErrors.company_name = msg;
      break;
      case 's1_company_size':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_size_req');
        fieldValidationErrors.company_size = msg;
      break;
      case 's1_company_sub_title':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_sub_title_req');
        fieldValidationErrors.company_sub_title = msg;
      break;
      case 's1_company_type':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_type_req');
        fieldValidationErrors.company_type = msg;
      break;
      case 's1_description':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_description_req');
        fieldValidationErrors.about_company = msg;
      break;
      case 's1_industry_type':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('industry_type');
        fieldValidationErrors.industry_type = msg;
      break;
      case 's1_email_id':
        msg = '';
        emailValid =emailRegix(value);
        if(valLength===0) msg = Helper.flashMsg('company_email_req');
        else if(!emailValid) msg = Helper.flashMsg('emailNotValid');
        fieldValidationErrors.email_id = msg;
        break;
        case 's1_i_agree':
          msg = '';
          if(!value) msg = Helper.flashMsg('company_iagree_req');
          fieldValidationErrors.i_agree = msg;
          break;
       
      break;
        default:
        break;
    }
  if( (state.s1_company_name!=='' && state.s1_company_name!==undefined) && 
  (state.s1_company_size!=='' && state.s1_company_size!==undefined) && 
  (state.s1_description!=='' && state.s1_description!==undefined) && 
  (state.s1_industry_type!=='' && state.s1_industry_type!==undefined) && 
  (state.s1_email_id!=='' && state.s1_email_id!==undefined) && 
  (state.s1_i_agree!=='' && state.s1_i_agree!==undefined) && 
  msg===''){
    formValid = true;
  }
  return { formValid: formValid, formErrors: fieldValidationErrors };
}
function companyAddressStep(fieldName, value, state) {
  let fieldValidationErrors = state.companyAddressFormErrors;
  let formValid = false;
  let valLength = value.length;
  let msg = '';
    switch(fieldName) {
      case 's2_address':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_address_req');
        fieldValidationErrors.company_name = msg;
      break;
      case 's2_country':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_country_req');
        fieldValidationErrors.company_size = msg;
      break;
      case 's2_state':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_state_req');
        fieldValidationErrors.company_sub_title = msg;
      break;
      case 's2_city':
        msg = '';
        if(valLength===0) msg = Helper.flashMsg('company_city_req');
        fieldValidationErrors.company_type = msg;
      break;
      case 's2_zipcode':
          msg = '';
          if(valLength===0) msg = Helper.flashMsg('company_zipcode_req');
          fieldValidationErrors.about_company = msg;
        break;
      
        default:
        break;
    }
  if( (state.s2_address!=='' && state.s2_address!==undefined) && (state.s2_country!=='' && state.s2_country!==undefined) && (state.s2_state!=='' && state.s2_state!==undefined) && (state.s2_city!=='' && state.s2_city!==undefined) && (state.s2_zipcode!=='' && state.s2_zipcode!==undefined) && msg===''){
    formValid = true;
  }
  return { formValid: formValid, formErrors: fieldValidationErrors };
}

function forgotPassForm(fieldName, value, state) {
  let fieldValidationErrors = state.passFormErrors;
  let passwordValid = false;
  let formValid = false;
  let valLength = value.length;
  let msg = '';
 switch(fieldName) {
      case 'new_password':
          msg = '';
        if(valLength===0){
          msg = 'Password is required';
        } if(valLength<=5){
          msg = 'Password is too short';
        } 
        fieldValidationErrors.new_password = msg;
        break;
      case 'conf_password':
          msg = '';
          if(valLength===0){
            msg = 'Confirm password is required';
          } if(valLength<=5){
            msg = 'Password is too short';
          } else if(state.new_password!==value){
            msg = 'Password and confirm password not match';
          }
        fieldValidationErrors.conf_password = msg;
        break;
      default:
        break;
    }
    if( 
     (state.new_password!=='' && state.new_password!==undefined) && 
      (state.conf_password!=='' && state.conf_password!==undefined) && 
      msg===''){
      formValid = true;
    }

  return { formValid: formValid, formErrors: fieldValidationErrors };
}

function fileValidation(file=null,fileType=null){
      if(file===null){
        return '';
      }
      //let validExtentions = ['png', 'jpg', 'gif', 'bmp', 'jpeg', 'PNG', 'JPG', 'GIF', 'BMP', 'JPEG'];
      let validExtentions = ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'];
      // in kb
      const filesSizes = Helper.fileSizesObj();
      const name = file.name;
      const lastDot = name.lastIndexOf('.');
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = parseInt(file.size/1024); //convert bytes to kb
      const indexOf = validExtentions.indexOf(ext);

      if(indexOf === -1){
        return 'Please select valid image type';
      } else if(size > filesSizes[fileType]) {
        return 'Max allowed image size '+filesSizes[fileType]+ ' KB';
      } else {
        return '';
      }
      
}
export default {
  signupForm,
  loginForm,
  companyInfoStep,
  companyAddressStep,
  isEmailValid,
  forgotPassForm,
  fileValidation
}
