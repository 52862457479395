//Footer of the application
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    }
  }

  render() {
    return (
      <footer id="footer">
        <div className="mainFooter">
          <ul>
            <li>
              <Link to="/term-conditions">Privacy</Link>
            </li>
            <li>
              <Link to="/term-conditions">Terms and conditions</Link>
            </li>
            <li>
              <a href="#">Cookies</a>
            </li>
            <li>
              <a href="#">Disclaimer</a>
            </li>
            <li>
              <a href="#">Who We Are </a>
            </li>
          </ul>

        </div>
        <div className="copyright">
          &#169; 2019 Copyright Comrate. All rights  reserved
            </div>
      </footer>
    )
  }
}
export default Footer;
