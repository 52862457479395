import * as types from './types'
import * as Api from '../../api/agent'
import { dispatchAction } from '../../utils/HelperFunc';


export const updateUserAction = (data,formData) => { 
    return async dispatch => {
        function updateUserSuccess(payload) {
            return dispatch({
                type: types.UPDATE_USER_ACTION_SUCCESS,
                payload: payload
            })
        }

        function updateUserFail(error) {
            return dispatch({
                type: types.UPDATE_USER_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.UPDATE_USER_ACTION_REQUEST })
            const payload = await Api.updateUser(data,formData,'userPersonalData')
            dispatchAction(
                payload.userPersonalData,
                updateUserSuccess,
                updateUserFail
            )
        } catch (error) {
            dispatch(updateUserFail(error))
        }
    }
}

export const addRating = (data) => { 
    return async dispatch => {
        function addRatingSuccess(payload) {
            return dispatch({
                type: types.UPDATE_USER_ACTION_SUCCESS,
                payload: payload
            })
        }

        function addRatingFail(error) {
            return dispatch({
                type: types.UPDATE_USER_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.UPDATE_USER_ACTION_REQUEST })
            const payload = await Api.ratingData(data,'addRatingData')
            dispatchAction(
                payload.addRatingData,
                addRatingSuccess,
                addRatingFail
            )
        } catch (error) {
            dispatch(addRatingFail(error))
        }
    }
}

export const getReviewBycompanyidAction = (companyid) => { 
    return async dispatch => {
        function getReviewBycompanyidActionSuccess(payload) {
            return dispatch({
                type: types.GET_REVIEW_BY_COMPANY_ID_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getReviewBycompanyidActionFail(error) {
            return dispatch({
                type: types.GET_REVIEW_BY_COMPANY_ID_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_REVIEW_BY_COMPANY_ID_ACTION_REQUEST })
            let payload = await Api.getReviewBycompanyidAction(companyid, 'reviewerData')
            dispatchAction(
                payload.reviewerData,
                getReviewBycompanyidActionSuccess,
                getReviewBycompanyidActionFail
            )
        } catch (error) {
            dispatch(getReviewBycompanyidActionFail(error))
        }
    }
}


export const getConnectionStatusByCompany = (data) => { 
    return async dispatch => {
        function getConnectionStatusByCompanySuccess(payload) {
            return dispatch({
                type: types.GET_CONNECTION_STATUS_BY_COMPANY_SUCCESS,
                payload: payload
            })
        }

        function getConnectionStatusByCompanyFail(error) {
            return dispatch({
                type: types.GET_CONNECTION_STATUS_BY_COMPANY_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_CONNECTION_STATUS_BY_COMPANY_REQUEST })
            const payload = await Api.getConnectionStatusByCompany(data,'connectionStatus')
            dispatchAction(
                payload.connectionStatus,
                getConnectionStatusByCompanySuccess,
                getConnectionStatusByCompanyFail
            )
        } catch (error) {
            dispatch(getConnectionStatusByCompanyFail(error))
        }
    }
}

export const getConnectionSuggestionCompanies = (data) => { 
    return async dispatch => {
        function getConnectionSuggestionCompaniesSuccess(payload) {
            return dispatch({
                type: types.GET_CONNECTION_SUGGESTION_COMPANIES_SUCCESS,
                payload: payload
            })
        }

        function getConnectionSuggestionCompaniesFail(error) {
            return dispatch({
                type: types.GET_CONNECTION_SUGGESTION_COMPANIES_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_CONNECTION_SUGGESTION_COMPANIES_REQUEST })
            const payload = await Api.getConnectionSuggestionCompanies(data,'conSuggestionCompanies')
            dispatchAction(
                payload.conSuggestionCompanies,
                getConnectionSuggestionCompaniesSuccess,
                getConnectionSuggestionCompaniesFail
            )
        } catch (error) {
            dispatch(getConnectionSuggestionCompaniesFail(error))
        }
    }
}

export const updatePostComments = (data) => { 
    return async dispatch => {
        function updatePostCommentsSuccess(payload) {
            return dispatch({
                type: types.UPDATE_POST_COMMENTS_SUCCESS,
                payload: payload
            })
        }

        function updatePostCommentsFail(error) {
            return dispatch({
                type: types.UPDATE_POST_COMMENTS_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.UPDATE_POST_COMMENTS_REQUEST })
            const payload = await Api.updatePostComments(data,'editPostComments')
            dispatchAction(
                payload.editPostComments,
                updatePostCommentsSuccess,
                updatePostCommentsFail
            )
        } catch (error) {
            dispatch(updatePostCommentsFail(error))
        }
    }
}
export const deletePostComments = (data) => { 
    return async dispatch => {
        function deletePostCommentsSuccess(payload) {
            return dispatch({
                type: types.DELETE_POST_COMMENTS_SUCCESS,
                payload: payload
            })
        }

        function deletePostCommentsFail(error) {
            return dispatch({
                type: types.DELETE_POST_COMMENTS_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.DELETE_POST_COMMENTS_REQUEST })
            const payload = await Api.deletePostComments(data,'deleteComments')
            dispatchAction(
                payload.deleteComments,
                deletePostCommentsSuccess,
                deletePostCommentsFail
            )
        } catch (error) {
            dispatch(deletePostCommentsFail(error))
        }
    }
}
export const companyPokeAction = (data) => { 
    return async dispatch => {
        function companyPokeActionSuccess(payload) {
            return dispatch({
                type: types.COMPANY_POKE_ACTION_SUCCESS,
                payload: payload
            })
        }

        function companyPokeActionFail(error) {
            return dispatch({
                type: types.COMPANY_POKE_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.COMPANY_POKE_ACTION_REQUEST })
            const payload = await Api.companyPokeAction(data,'pokeData')
            dispatchAction(
                payload.pokeData,
                companyPokeActionSuccess,
                companyPokeActionFail
            )
        } catch (error) {
            dispatch(companyPokeActionFail(error))
        }
    }
}
export const getcompanyPokeAction = (data) => { 
    return async dispatch => {
        console.log("im in dispatch");

        function getcompanyPokeActionSuccess(payload) {
            console.log("im in success")
      
            return dispatch({
                type: types.GET_COMPANY_POKE_ACTION_SUCCESS,
                payload: payload
            })
        }

        function getcompanyPokeActionFail(error) {
            console.log("im in failure")
      
            return dispatch({
                type: types.GET_COMPANY_POKE_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            console.log("im in try")

            dispatch({ type: types.GET_COMPANY_POKE_ACTION_REQUEST })
            const payload = await Api.getcompanyPokeAction(data,'getpokeData')
            console.log('payload');
            console.log(payload);
       
            dispatchAction(
                payload.getpokeData,
                getcompanyPokeActionSuccess,
                getcompanyPokeActionFail
            )
        } catch (error) {
            dispatch(getcompanyPokeActionFail(error))
        }
    }
}

export const getcompanyPokeByStatusAction = (data) => { 
    console.log("poke func");
    // console.log(data)
    return async dispatch => {
        console.log("im in dispatch");
        function getcompanyPokeByStatusActionSuccess(payload) {
            console.log("im in success")
            return dispatch({
                type: types.GET_COMPANY_POKE_BY_STATUS_ACTION_SUCCESS,
                payload: payload
            })
        }
        

        function getcompanyPokeByStatusActionFail(error) {
            console.log("im in failure")
            return dispatch({
                type: types.GET_COMPANY_POKE_BY_STATUS_ACTION_FAILURE,
                payload: error
            })
        }
        try {

            console.log("im in try")
            dispatch({ type: types.GET_COMPANY_POKE_BY_STATUS_ACTION_REQUEST })
            const payload = await Api.getcompanyPokeByStatusAction(data,'pokeDetails')
            // console.log('payload');
            // console.log(payload);
            dispatchAction(
                payload.pokeDetails,
                getcompanyPokeByStatusActionSuccess,
                getcompanyPokeByStatusActionFail
            )
        } catch (error) {
            dispatch(getcompanyPokeByStatusActionFail(error))
        }
    }
}
export const updatecompanyPokeAction = (data) => { 
    return async dispatch => {
        function updatecompanyPokeActionSuccess(payload) {
            return dispatch({
                type: types.UPDATE_COMPANY_POKE_ACTION_SUCCESS,
                payload: payload
            })
        }

        function updatecompanyPokeActionFail(error) {
            return dispatch({
                type: types.UPDATE_COMPANY_POKE_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.UPDATE_COMPANY_POKE_ACTION_REQUEST })
            const payload = await Api.updatecompanyPokeAction(data,'updatepokeData')
             dispatchAction(
                payload.updatepokeData,
                updatecompanyPokeActionSuccess,
                updatecompanyPokeActionFail
            )
        } catch (error) {
            dispatch(updatecompanyPokeActionFail(error))
        }
    }
}
export const companyUnFollowAction = (data) => { 
    return async dispatch => {
        function companyUnFollowActionSuccess(payload) {
            return dispatch({
                type: types.COMPANY_UNFOLLOW_ACTION_SUCCESS,
                payload: payload
            })
        }

        function companyUnFollowActionFail(error) {
            return dispatch({
                type: types.COMPANY_UNFOLLOW_ACTION_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.COMPANY_UNFOLLOW_ACTION_REQUEST })
            const payload = await Api.companyUnFollowAction(data,'unfollow')
             dispatchAction(
                payload.unfollow,
                companyUnFollowActionSuccess,
                companyUnFollowActionFail
            )
        } catch (error) {
            dispatch(companyUnFollowActionFail(error))
        }
    }
}
export const getNotifications = (data) => { 
    return async dispatch => {
        function getNotificationsSuccess(payload) {
            return dispatch({
                type: types.GET_NOTIFICATIONS_SUCCESS,
                payload: payload
            })
        }

        function getNotificationsFail(error) {
            return dispatch({
                type: types.GET_NOTIFICATIONS_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_NOTIFICATIONS_REQUEST })
            const payload = await Api.getNotifications(data,'getNotificationsres')
             dispatchAction(
                payload.getNotificationsres,
                getNotificationsSuccess,
                getNotificationsFail
            )
        } catch (error) {
            dispatch(getNotificationsFail(error))
        }
    }
}

export const updateReadStatus = (data) => { 
    return async dispatch => {
        function updateReadStatusSuccess(payload) {
            return dispatch({
                type: types.UPDATE_READ_STATUS_SUCCESS,
                payload: payload
            })
        }

        function updateReadStatusFail(error) {
            return dispatch({
                type: types.UPDATE_READ_STATUS_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.UPDATE_READ_STATUS_REQUEST })
            const payload = await Api.updateReadStatus(data,'updateReadStatusres')
             dispatchAction(
                payload.updateReadStatusres,
                updateReadStatusSuccess,
                updateReadStatusFail
            )
        } catch (error) {
            dispatch(updateReadStatusFail(error))
        }
    }
}
export const getTypeaheadSearchResult = (companyid) => { 
    return async dispatch => {
        function getTypeaheadSearchResultSuccess(payload) {
            return dispatch({
                type: types.GET_TYPE_AHEAD_SEARCH_RESULT_SUCCESS,
                payload: payload
            })
        }

        function getTypeaheadSearchResultFail(error) {
            return dispatch({
                type: types.GET_TYPE_AHEAD_SEARCH_RESULT_FAILURE,
                payload: error
            })
        }
        try {
            dispatch({ type: types.GET_REVIEW_BY_COMPANY_ID_ACTION_REQUEST })
            let payload = await Api.getTypeaheadSearchResult(companyid, 'typeaheadSearchresult')
            dispatchAction(
                payload.typeaheadSearchresult,
                getTypeaheadSearchResultSuccess,
                getTypeaheadSearchResultFail
            )
        } catch (error) {
            dispatch(getTypeaheadSearchResultFail(error))
        }
    }
}

