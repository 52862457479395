import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
// import Helper from "../../../../Configs/Helper";

class FormStep3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countFieldSet:1,
      fieldSetArr:this.props.state.fouderStepFieldsArr,
    };
    this.addMoreHandler = this.addMoreHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);
  }
  addMoreHandler(){
    let fieldSetArr = this.state.fieldSetArr;
    let countFieldSet = this.state.countFieldSet;
    countFieldSet = countFieldSet+1;
    fieldSetArr.push(countFieldSet);
    this.setState({countFieldSet, fieldSetArr}, this.props.fieldsArrHandler.bind(this,'founder',this.state.fieldSetArr) );
  }
  removeHandler(val,event){
    let fieldSetArr = this.state.fieldSetArr;
    var index = fieldSetArr.indexOf(val);
    if (index > -1) {
      fieldSetArr.splice(index, 1);
    }
    this.setState({fieldSetArr}, this.props.fieldsArrHandler.bind(this,'founder',this.state.fieldSetArr) );
  }


  render() {
    return (
      <div className="box-body">
      <ul className="deailsList formAction" id="founderList">
        <FormFields state={this.state} parentState={this.props.state} removeHandler={this.removeHandler} onChnageHandler={this.props.onChnageHandler}/>
      </ul>
      <div className="text-right" style={{marginBottom:"15px"}}>
        <a href="#" className="btn btn-xs btn-link" id="addFounderBtn" onClick={this.addMoreHandler}><i className="fa fa-plus" /> Add More</a>
      </div>
    </div>
    
    );
  }
}

function FormFields(props){
  let arrLength = props.state.fieldSetArr.length;
  let i=0;
  let fieldsSet = [];
  props.state.fieldSetArr.forEach(function(val){
    let imgPrevNameVar = 's3_founder_image_'+val+'_preview';
    let bgImg = {};
    if(props.parentState[imgPrevNameVar]!==undefined){
      bgImg = {backgroundImage: 'url('+props.parentState[imgPrevNameVar]+')'};
    }

    let nameState = '';
    if(props.parentState['s3_founder_name_'+val] !==undefined ){
      nameState = props.parentState['s3_founder_name_'+val];
    }
    let detailsState = '';
    if(props.parentState['s3_founder_detail_'+val] !==undefined ){
      detailsState = props.parentState['s3_founder_detail_'+val];
    }
    fieldsSet.push(
      <li key={val} className="imageContainer">
      <div>
        <div className="label">Image</div>
        <div className="details">
          <div className="image-preview" style={bgImg}>

          </div>
          <div className="upload-options">
            <input type="file" className="image-upload form-control-file"  name={`s3_founder_image_${val}`} data-name="founderImg" onChange={props.onChnageHandler} accept="image/*" />
          </div>
          <div className="tooltip_icon"><i className="fa fa-info-circle" aria-hidden="true"></i>
          <span className="tooltip_text">Image should be in .jpg, .jpeg or .png format</span> 
          </div>
        </div>
      </div>
      <div>
        <div className="label">Name</div>
        <div className="details">
          <input className="form-control" type="text" name={`s3_founder_name_${val}`} onChange={props.onChnageHandler} value={nameState}/>
        </div>
      </div>
      <div>
        <div className="label">Position</div>
        <div className="details">
          <input className="form-control" type="text" name={`s3_founder_detail_${val}`} onChange={props.onChnageHandler} value={detailsState}/>
          { (val>1) && <div className="delete-btn-div">
              <button className="btn btn-danger btn-xs" type="button" onClick={props.removeHandler.bind(this,val)}><i className="fa fa-trash"/></button>
            </div> 
          }
        </div>
       
      </div>
      <div>
      
      </div>
      
    </li>
    );
  })
  return fieldsSet;
}

export default (FormStep3);
